/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useOrders,
  useCurrencies,
  useUser,
  useSystemSettings,
} from 'react-omnitech-api'
import { usePriceTemplate } from '../../../hook'
import { withAuthenticableNavigationBlock } from '../../../ui'
import AccountOrdersView from './account-orders-view'

function AccountOrdersController() {
  const PRICE_TEMPLATE_KEY = _.get(usePriceTemplate(), 'code')
  const {
    fetchOrders,
  } = useOrders()
  const { t } = useTranslation()
  const { user } = useUser()
  const { currencies } = useCurrencies()
  const { getSystemSetting } = useSystemSettings()
  const currency = _.find(currencies, { isBaseCurrency: true })
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [fetchNextOrders, setFetchNextOrders] = useState(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isOrdersReady, setIsOrdersReady] = useState(false)
  const [pageReady, setPageReady] = useState(false)
  // local variable
  const seoTitle = t('screens.accountOrders.seo.title')

  const handleFecthOrders = useCallback(async (fetchNextPage) => {
    setLoading(true)
    try {
      const extraParams = getSystemSetting('api.v2.orders.index.my_orders.ecom.query', {})
      const apiParams = {
        includes: [
          'price_details',
          'orders.return_requests',
          'return_requests.return_request_lines',
          'shipments',
          'shipments.order_lines',
          'order_lines.color_option',
          'order_line_properties',
          'order_line_properties.color_option',
          'color_options.images',
        ],
        pageSize: 10,
        priceTemplate: PRICE_TEMPLATE_KEY,
        ...extraParams,
      }

      const { orders: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchOrders(apiParams)
      // update state
      setOrders((prevState) => prevState.concat(data))
      setHasMoreOrders(_.isFunction(next))
      setFetchNextOrders(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsOrdersReady(true)
      }
    } catch (error) {
      console.error('fetchOrders error: ', error)
    } finally {
      setPageReady(true)
      setLoading(false)
    }
    // const promise = fetchOrders(apiParams)
    // promise.then((data) => {
    //   setOrders(data.orders)
    // })
  }, [fetchOrders])

  function handleFetchOrdersNextPage() {
    if (!_.isFunction(fetchNextOrders)) return
    if (!loading) {
      handleFecthOrders(fetchNextOrders)
    }
  }

  /**
   * get orders
   */
  useEffect(() => {
    if (!loading) {
      handleFecthOrders()
    }
    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchOrders',
      ])
    }
  }, [handleFecthOrders])

  const viewProps = {
    currency,
    loading,
    pageReady,
    orders,
    seoTitle,
    user,
    hasMoreOrders,
    isOrdersReady,
    onFetchOrdersNextPage: handleFetchOrdersNextPage,
  }
  return (
    <AccountOrdersView {...viewProps} />
  )
}

export default withAuthenticableNavigationBlock(AccountOrdersController)
